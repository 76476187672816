@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './utils/PhoneFlag.css';
@import '@fontsource/fira-sans';

body,
option {
    background-color: #2F292B !important;
    font-family: 'Fira Sans', sans-serif !important;
}

.c-main{
    position: relative!important;
    bottom: 8%;
}

.creds input {
    color: black !important;
}

table {
    width: 100%;
    margin-bottom: 1%;
    border-bottom: 1px solid white;
}

table tr {
    margin-bottom: 1%;
    display: flex;
    justify-content: space-between;
}

th {
    font-size: 16px !important;
    font-family: 'Fira Sans', sans-serif !important;
    color: white !important;
}

.noNodes .MuiSvgIcon-root {
    font-size: 60px !important;
}

h1 {
    line-height: normal;
    font-weight: 500;
    color: white;
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 500;
    font-size: 50px;
}

h2 {
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: white;
}

h3{
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: white;
}

label {
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.2%;
    color: #A2A2A2;
}

h4 {
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: white;
}

h6,
.some-text {
    color: white !important;
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

h5 {
    font-family: 'Fira Sans', sans-serif !important;
    font-size: 18px !important;
    color: white !important;
}

p,
span {
    color: white;
    font-family: 'Fira Sans', sans-serif !important;
}

.grey-text{
    color: #A2A2A2!important;
    font-weight: 100!important;
}

.Mui-selected .MuiListItemText-root span {
    color: #fff !important;
}

span.MuiListItemText-primary {
    color: #999999 !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #F55937 !important;
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected {
    border-left: 4px solid #F55937 !important;
}

.MuiButtonBase-root.MuiTab-root {
    color: black;
}

.Info-page span {
    font-size: 22px;
}

.MuiDataGrid-columnHeader {
    font-size: 16px !important;
    font-family: 'Fira Sans', sans-serif !important;
    color: white !important;

}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    color: white !important;
}

.MuiStepper-root {
    display: none !important;
}

.MuiDataGrid-cell {
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 500 !important;
    color: white !important;
}

.MuiStepConnector-line {
    display: none !important;
}

.MuiStep-horizontal {
    background-color: #D8D8D8 !important;
    margin: 0 2%;
}

.Mui-completed {
    background-color: #34B53A !important;
}

.MuiInputBase-root {
    border-radius: 0 !important;

}

.MuiOutlinedInput-root {
    border: 0;
}

.MuiPaper-root {
    background-color: white !important;
}

.MuiDrawer-paper {
    background-color: #2F292B !important;
    border-right: 1px solid #ffffff47 !important;
}

.MuiDataGrid-cell--textLeft {
    border-right: 2px solid white !important;
}

.MuiToolbar-root {
    border-right: 1px solid #ffffff47 !important;
}

.MuiIconButton-root {
    color: white !important;
}

.MuiDataGrid-main,
.css-6hp17o-MuiList-root-MuiMenu-list,
.MuiDataGrid-topContainer,
.MuiDataGrid-columnHeaders,
.MuiDataGrid-container--top [role=row],
.MuiDataGrid-footerContainer {
    background-color: #2F292B !important;
    color: white !important;
}

.MuiDataGrid-main{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.MuiDataGrid-footerContainer {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

input:focus-visible {
    outline: none !important;
}

input {
    width: 100%;
}

.AisdMenu {
    width: calc(64px + 1px);
}

.css-wvyq5i {
    padding: 12px 6px !important;
}

.css-1r9jet7 {
    width: 100% !important;
}

#button-text {
    color: white !important;
}

.HostButton {
    border-radius: 10px !important;
    border: 3px solid #F55937 !important;
    background-color: transparent !important;
    color: #F55937 !important;
    box-shadow: none !important;
    text-transform: none !important;
    padding: 4px 8px !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    z-index: 1220 !important;
    font-weight: bold !important;
}

.MuiTablePagination-select {
    color: white !important;
}

.MuiInputBase-input{
    padding-right: 43px!important;
}

.MuiDataGrid-overlay {
    background-color: #2F292B !important;
}

/* Define styles for the scrollbar of the DataGrid */
.MuiDataGrid-scrollbar::-webkit-scrollbar {
    width: 12px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
    background-color: #ffc5b8;
    border-radius: 20px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
    background-color: #F55937;
    border-radius: 20px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #9a1c00;
}

.HostingTable .MuiSvgIcon-root,
.PaymentTable .MuiSvgIcon-root {
    color: white !important;
}

.orangeText {
    color: #F55937 !important;
    font-size: 14px;
}

.greenTxt {
    color: #03e403 !important;
}

.redTxt {
    color: #F55937 !important;
    font-size: 14px !important;
}

.MuiBox-root {
    padding-bottom: 0 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

#invoice-content h2,
#invoice-content h1,
#invoice-content p,
#invoice-content span,
#invoice-content h3,
#invoice-content h4 {
    color: black !important;
}

#invoice-content tr {
    border-bottom: 2px solid black !important;
    padding-bottom: 1%;
}

#invoice-content tr:last-child {
    border-bottom: none!important;
}

#InvoiceDialog span {
    color: black !important;
}
#DashboardDialog .MuiDialog-paper {
    width: 100% !important;
    max-width: 60% !important;
}

.MuiDialog-paper {
    width: 100% !important;
    max-width: 800px !important;
}

.Network .MuiSvgIcon-root{
    font-size: 2rem!important;
    color: #F55937!important
}

.Banner h6{
    font-size: 8px!important;
}

.Banner .MuiSvgIcon-root{
    font-size: 1.2rem;
}

.copyIcon .MuiSvgIcon-root{
    font-size: 0.9rem;
}


.caseOne button .MuiSvgIcon-root{
    font-size: 0.8rem!important;
}

.caseOne input, .caseOne button{
    font-size: 0.5rem!important;
    line-height: normal!important;
}


.Network .tableNetwork{
    height: auto; 
    width: 55%; 
}

.Network .tableWallet{
    height: 87vh; 
}

.Network .tableSubs{
    height: 75vh; 
}

.Network .NotableNetwork{
    height: 70vh; 
    width: 55%; 
}

.Network .tableNetwork .MuiDataGrid-root{
    border-radius: 0.5rem!important;
}

.profile .bills{
    height: auto; 
    width: 95%; 
    border-radius: 0.5rem;
}

.orange-500{
    color: #F55937!important;
}

.DialogPop .MuiPaper-root {
    border-radius: 0.5rem!important;
    border: 2px solid white!important;
}

.profile .noBills{
    height: 50vh;
    width: 95%;
    border-radius: 0.5rem;
}
 
.popupProfile .MuiIconButton-root  {
   color: #2F292B!important;
}

.profile .border-button-container {
    position: relative;
    border: 2px solid #F55937;
    display: inline-block;
}

.profile .border-button-container::after {
    content: "CONNECTED";
    position: absolute;
    top: -1.25rem; 
    left: 22%;
    transform: translateX(-50%);
    background-color: #F55937; 
    color: #FFF; 
    padding: 3px 30px; 
    border-radius: 0.25rem; 
    font-size: 0.875rem;
    font-weight: 600;
    box-shadow: 3px 2px 4px 1px #0000006e;
    background-image: url('../assets/images/connected.png');
    background-repeat: no-repeat;
    background-position: 9px 5px;
}

.profile .border-button-container-white::after{
    content: " CONNECT";
    position: absolute;
    top: -1.25rem; 
    left: 22%;
    transform: translateX(-50%);
    background-color: #F55937; 
    color: #FFF; 
    padding: 3px 30px; 
    border-radius: 0.25rem; 
    background-position: 8px 3px;
    font-size: 0.875rem;
    font-weight: 600;
    box-shadow: 3px 2px 4px 1px #0000006e;
    background-image: url('../assets/images/connect.png');
    background-repeat: no-repeat;
}

.profile .border-button-container-white{
    position: relative;
    border: 2px solid white;
    display: inline-block;
}

.profile .delete-icon .MuiSvgIcon-root, .profile .MuiSvgIcon-root{
    font-size: 1rem!important;
}

.profile .InfoHere .MuiSvgIcon-root{
    font-size: 1.2rem!important;
}

.profile .inputTT input{
    font-size: 80%!important;
}

.MuiDialog-container .MuiPaper-root {
    background-color: #2F292B!important;
}

.MuiDialog-container  .MuiDialogContentText-root{
    color: white!important;
}

#onlyHere{
    justify-content: space-between!important;
    align-items: center;
}

#ajaCenter{
    justify-content: center!important;
    align-items: center;
    padding-bottom: 4%;
}

.MuiBadge-badge{
    background: #F55937!important;
    left: -0.7rem!important;
}

.primary-btt {
    background: #F55937;
    border: 2px solid #F55937;
    color: #fff;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
  }
  .primary-btt:hover {
    background: #E9401B;
  }

#overlay-1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1300;
  }
  
  #cookie-consent {
    padding: 1%;
    position: fixed;
    background-color: #2F292B;
    z-index: 10;
    width: 28%;
    top: 12%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
    left: 35%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  }


#cookie-settings {
    position: fixed;
    background-color: #2F292B;
    z-index: 10;
    width: 50%;
    top: 12%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
    left: 25%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  }
  #cookie-settings .toggle-container p {
    font-weight: 400 !important;
  }
  #cookie-settings .orange-no-btn {
    background: #F55937;
    padding: 2% 4%;
    text-align: center;
  }
  #cookie-settings .orange-no-btn p {
    margin: 0;
    font-weight: 400;
  }
  #cookie-settings .grey-no-btn {
    background: #808080;
    padding: 2% 4%;
    text-align: center;
  }
  #cookie-settings .grey-no-btn p {
    margin: 0;
  }
  #cookie-settings .cookie-box {
    padding: 1%;
    height: 85vh;
  }
  #cookie-settings .cookie-box .img-logo {
    display: flex !important;
    justify-content: flex-end;
  }
  #cookie-settings .cookie-box .pos-rel {
    position: relative;
    bottom: 40px;
    padding: 20px 6% 5% 6%;
  }
  
  #cookie-consent .cookie-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #cookie-consent p {
    margin: 0;
    margin-bottom: 1%;
    font-weight: 400;
    text-align: center;
  }
  
  #cookie-consent .op-color {
    border-radius: 0.5rem;
    padding: 1.5% 1%;
    margin: 2% 4%;
  }
  
  #cookie-settings .op-color {
    border-radius: 0.5rem;
    padding: 1.1% 1%;
    margin: 1% 0%;
  }
  
  #cookie-consent .op-decline, #cookie-settings .op-decline {
    background-color: #808080;
    border: 2px solid #808080;
  }
  
  #cookie-consent .btn-contains, #cookie-settings .btn-contains {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .fade-out {
    opacity: 0;
    display: none !important;
  }

  .toggle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    color: white;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    margin-left: 20%;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }



@media (max-width: 300px) {
    .HostButton {
        padding: 2px 2px !important;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 8px;
    }

        
    .c-main{
        bottom: 1%;
    }

    .Network .tableNetwork, .Subscriptions {
        height: auto; 
        width: 80% 
    }

    .Network .NotableNetwork{
        height: 75vh; 
        width: 80% 
    }

    .profile .MuiDataGrid-cellEmpty{
        width: 0px!important;
        padding: 0;
        margin: 0;
    }

    #fonFont{
        font-size: 10px;
        margin-top: 1%;
    }
}

@media (min-width: 301px) and (max-width: 637px) {
    #cookie-settings, #cookie-consent {
        width: 95%!important;
        left: 3%;
        padding: 4% 4%;
    }

    #cookie-consent{
        top: 16%;
    }

    #cookie-settings{
        top: 7%;
    }

    .toggle-switch {
        margin-left: 2%;
    }

    #cookie-settings .cookie-box {
        padding: 1%;
        height: 67vh;
      }

    #fonFont{
        font-size: 10px;
        margin-top: 1%;
    }
    
    .c-main{
        bottom: 1%;
    }
    
    .AisdMenu {
        width: calc(56px + 1px);
    }

    h1 {
        font-size: 22px;
    }

    h5 {
        font-size: 10px !important;
    }

    .multi-step-container h2 {
        font-size: 14px;
    }

    .caseOne h5 {
        font-size: 10px !important;
    }

    .Info-page span {
        font-size: 14px;
    }

    label {
        font-size: 14px !important;
    }

    .case-1 h6 {
        font-size: 11px !important;
    }

    table {
        width: 100%;
        overflow-x: scroll !important;
        white-space: nowrap;
    }

    table tr th {
        font-size: 12px !important;
        margin-right: 1% !important;
        padding-right: 1% !important;
        border-right: 1px solid rgba(255, 255, 255, 0.321) !important;
    }

    .MuiBox-root {
        padding: 0 !important;
    }


    .noNodes .MuiSvgIcon-root {
        font-size: 30px !important;
    }

    .noServer h3 {
        font-size: 10px !important;
    }

    .greenTxt {
        font-size: 12px !important;
    }

    .redTxt {
        font-size: 10px !important;
    }

    .summary h6 {
        font-size: 10px !important;
    }

    .summary .redTxt {
        font-size: 8px !important;
    }

    .summary .orangeText {
        font-size: 8px !important;
    }

    .hideIcon {
        display: none !important;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-size: 11px !important;
    }

    .MuiDataGrid-cell {
        font-size: 8px;
    }

    .MuiDataGrid-row {
        max-height: 30px !important;
        min-height: 30px !important;
        --height: 30px !important;
    }

    .MuiDataGrid-iconButtonContainer {
        width: 20% !important;
    }

    .PaymentTable .MuiSvgIcon-root {
        font-size: 15px !important;
    }

    
    h2 {
        font-size: 16px;
    }

    h3{
        font-size: 13px;
    }

    .refferal p{
        font-size: 8px;
        line-height: 8px;
    }

    .refferal .MuiSvgIcon-root{
        font-size: 1.1rem;
    }

    .Banner .MuiSvgIcon-root{
        font-size: 0.7rem;
        padding: 1px;
    }

    .Banner h6{
        font-size: 7px!important;
    }
    
    .Network .tableNetwork{
        height: auto; 
        width: 95% 
    }

    .Network .NotableNetwork{
        height: 70vh; 
        width: 95% 
    }

    .profile .bills{
        height: auto;  
        width: 95%; 
    }

    .profile .noBills{
        height: 70vh;  
        width: 95%; 
    }

    
    .Network .MuiDataGrid-row:last-child , .profile .MuiDataGrid-row:last-child{
        border-bottom: 2px solid white;
    }

    .profile .MuiButton-root {
        font-size: 10px!important;
    }

    p{
        font-size: 12px;
    }

    .Dash p{
        font-size: 9px;
    }

    .MobileTxt h6{
        font-size: 8px!important;
    }

    .MobileTxt a{
        font-size: 8px!important;
    }

    .Network .MuiSvgIcon-root{
        font-size: 1.2rem!important;
    }

    .profile .border-button-container-white::after{
        font-size: 0.7rem;
    }

    .profile .border-button-container::after{
        font-size: 0.7rem;
        padding: 4px 30px; 
    }
    .MuiBadge-badge{
        top: 0!important;
        left: -1rem!important;
        position:absolute!important;
    }
}

@media (min-width: 638px) and (max-width: 992px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    table tr th {
        font-size: 12px !important;
        margin-right: 1% !important;
        padding-right: 1% !important;
        border-right: 1px solid rgba(255, 255, 255, 0.321) !important;
    }

    table {
        width: 100%;
        overflow-x: scroll !important;
        white-space: nowrap;
    }

}

@media (min-width: 600px) {
    .css-1r9jet7 {
        min-height: 55px !important;
    }
}